ul {
  padding: 0
}
html, body {
  width: 100%;
  height: 100dvh;
  background: #F3F5F7;
}

* {
  box-sizing: border-box;
}
video { object-fit: cover; }

.App {
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  /*max-width: 720px;*/
  /*margin: auto;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
